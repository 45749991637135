import { SupabaseClient } from "@supabase/supabase-js";
import { User } from "../recoil/user";
import { API_URL } from "./config";

// gets user info from google
export async function getUser(supabase: SupabaseClient) {
  if (!supabase) return;

  // Get the current Supabase session
  const {
    data: { session },
    error,
  } = await supabase.auth.getSession();
  if (!session) {
    console.error("No active session");
    return;
  }

  const supabaseToken = session.access_token; // Supabase access token for auth
  const googleToken = session.provider_token; // Google access token for Google API access

  if (!supabaseToken || !googleToken) {
    console.error("Missing tokens for authentication");
    return;
  }

  try {
    const response = await fetch(API_URL + "/google/get-user-info", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${supabaseToken}`, // Use Supabase token for backend authentication
      },
      body: JSON.stringify({
        googleToken: googleToken, // Use Google token for Google API access
      }),
    });

    const data = await response.json();
    console.log(data);

    if (response.ok) {
      const user: User = {
        email: data.emailAddresses[0].value,
        fullName: data.names[0].displayName,
        profileImg: data.photos[0].url,
      };
      console.log(user);
      return user;
    } else {
      console.error("Failed to get user info:", data.error);
      return undefined;
    }
  } catch (error) {
    console.error("Error making request:", error);
  }
}

// checks if user is in table
export async function checkPlaidUser(
  supabase: SupabaseClient,
  userId: string
): Promise<boolean> {
  try {
    // Query the plaid_items table with the specified userId
    const { data, error } = await supabase
      .from("plaid_items")
      .select("user_id")
      .eq("user_id", userId)
      .single(); // We expect either 0 or 1 rows

    // If there's an error or no data, return false
    if (error) {
      console.error("Error fetching plaid user:", error.message);
      return false;
    }

    // If data exists, return true; otherwise, return false
    return !!data;
  } catch (err) {
    console.error("Unexpected error:", err);
    return false;
  }
}

// updates user on auth success
export async function updateDatabaseWithGoogleInfo(
  supabase: SupabaseClient,
  setUser: Function
) {
  try {
    // Get the currently authenticated user from Supabase
    const {
      data: { user },
      error: userError,
    } = await supabase.auth.getUser();
    if (!user) return;
    const userId = user.id; // Supabase Auth user ID

    // Retrieve the session data, which includes OAuth token information
    const {
      data: { session },
    } = await supabase.auth.getSession();
    if (!session) throw new Error("No session data found");

    // Fetch Google profile details using Supabase user session
    const { email, user_metadata } = user;
    const googleUsername = user_metadata.full_name || email;

    // Update the `users` table with user details from Google
    const { error: updateUserError } = await supabase.from("users").upsert({
      user_id: userId, // Supabase user ID
      email: email,
      full_name: googleUsername,
    });

    if (updateUserError)
      throw new Error("Error updating user: " + updateUserError.message);

    // Update the `google_users` table with access and refresh tokens
    const response = await fetch(API_URL + "/google/save-tokens", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.access_token}`, // Use Supabase token for backend authentication
      },
      body: JSON.stringify({
        access_token: session.provider_token,
        refresh_token: session.provider_refresh_token,
      }),
    });

    // Set the user in Recoil state
    setUser({
      id: userId,
      email: email,
      fullName: googleUsername,
    });
  } catch (error: any) {
    console.error("Error during Google user info update: ", error.message);
  }
}

// get sheet id from user
export async function getSheetInfo(supabase: SupabaseClient) {
  const {
    data: { user },
    error: userError,
  } = await supabase.auth.getUser();
  if (!user) return;

  const userId = user.id;

  // Query the user_sheets table for the sheet_id and sheet_url
  const { data, error } = await supabase
    .from("user_sheets")
    .select("sheet_id")
    .eq("user_id", userId)
    .single();

  if (error) return null;
  return data.sheet_id;
}

// get sheet id from user
export async function getUserOnboardingStatus(supabase: SupabaseClient) {
  const {
    data: { user },
    error: userError,
  } = await supabase.auth.getUser();
  if (!user) return;

  const userId = user.id;

  // Query the user_sheets table for the sheet_id and sheet_url
  const { data, error } = await supabase
    .from("users")
    .select("onboarding_status")
    .eq("user_id", userId)
    .single();

  if (error) return null;
  return data.onboarding_status;
}

// updates the number of accounts user wants to pay for
export async function updateAccountCount(
  supabase: SupabaseClient,
  newAccountCount: number
) {
  // Get the current authenticated user
  const {
    data: { user },
    error: userError,
  } = await supabase.auth.getUser();

  if (!user) return;

  const userId = user.id;

  // Update the account_count field for the current user
  const { data, error } = await supabase
    .from("users")
    .update({ account_count: newAccountCount })
    .eq("user_id", userId);

  if (error) {
    console.error("Error updating account_count:", error.message);
    return;
  }

  return true;
}

// Deletes a user's sheet from the user_sheets table
export async function deleteUserSheet(supabase: SupabaseClient) {
  // Get the current authenticated user
  const {
    data: { user },
    error: userError,
  } = await supabase.auth.getUser();

  if (!user) {
    console.error("User not authenticated:", userError?.message);
    return;
  }

  const userId = user.id;

  // Delete the user's sheet entry from the user_sheets table
  const { data, error } = await supabase
    .from("user_sheets")
    .delete()
    .eq("user_id", userId);

  if (error) {
    console.error("Error deleting user sheet:", error.message);
    return;
  }

  return true;
}
