import { Box, Button, Flex, Heading, Text } from "@radix-ui/themes";
import { useSupabase } from "../../contexts/SupabaseContexts";
import { APP_URL } from "../../util/config";

export default function SessionExpiredPage() {
  const { supabase } = useSupabase();

  const handleLogin = async () => {
    if (!supabase) return;

    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        scopes: "https://www.googleapis.com/auth/spreadsheets", // Request Google Sheets scope
        redirectTo: APP_URL + "/home",
        queryParams: {
          access_type: "offline",
          prompt: "consent",
        },
      },
    });
  };

  return (
    <Flex direction="column" gap="2">
      <Heading>Your session has expired.</Heading>
      <Text size="4">Please log in again.</Text>

      <Box mt="4">
        <Button size="3" radius="full" onClick={handleLogin}>
          Log in with Google
        </Button>
      </Box>
    </Flex>
  );
}
