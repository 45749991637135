import {
  Button,
  Card,
  Flex,
  Heading,
  IconButton,
  Separator,
  Spinner,
  Text,
} from "@radix-ui/themes";
import { useCallback, useEffect, useState } from "react";
import { MinusIcon, PlusIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { FadeUp } from "../../../components/FadeUp";
import { useSupabase } from "../../../contexts/SupabaseContexts";
import { API_URL } from "../../../util/config";
import { PlaidLinkOptions, usePlaidLink } from "react-plaid-link";

export const ConnectBank = () => {
  const { supabase } = useSupabase();
  const navigate = useNavigate();
  const [linkToken, setLinkToken] = useState(null); // State to store the link token
  const [loading, setLoading] = useState(true);

  // Fetch the link token from the backend when the component mounts
  useEffect(() => {
    const fetchLinkToken = async () => {
      if (!supabase) return;
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (!session) return;
      const accessToken = session.access_token;
      const response = await fetch(API_URL + "/plaid/create-link-token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`, // Replace with the actual Supabase token
        },
      });
      const data = await response.json();
      if (response.ok) setLinkToken(data.link_token);
      else console.log(response);
    };

    fetchLinkToken(); // Call the function to fetch the link token
  }, [supabase]); // Empty dependency array ensures this runs only once on mount

  const exchangeTokenAndSave = async (public_token: string) => {
    if (!supabase) return;
    const {
      data: { session },
    } = await supabase.auth.getSession();
    if (!session) return;
    const accessToken = session.access_token;

    const response = await fetch(API_URL + "/plaid/exchange-public-token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`, // Replace with the actual Supabase token
      },
      body: JSON.stringify({
        public_token: public_token,
      }),
    });
    const data = await response.json();
    if (data.success) {
      // move to next stage
      navigate("/setup/verify-bank-connections");
    }
  };

  const config: PlaidLinkOptions = {
    token: linkToken,
    onSuccess: (public_token, metadata) => {
      setLoading(true);
      exchangeTokenAndSave(public_token);
    },
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
  };

  const { open, ready } = usePlaidLink(config);

  const handlePlaidLink = useCallback(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  useEffect(() => {
    if (!ready) setLoading(true);
    else setLoading(false);
  }, [ready]);

  return (
    <FadeUp>
      <Flex direction="column" gap="4">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzqMsB2z-ugehsuorxasvQ8WtcYvVceM5zkw&s"
          width="50px"
          height="50px"
          style={{ borderRadius: "15px" }}
        />

        <Heading>Connect Bank with Plaid</Heading>

        <Text>
          Securely link your bank account to start syncing your transactions to
          Google Sheets.
        </Text>

        <Flex gap="2" align="center">
          <Button size="3" onClick={handlePlaidLink} disabled={loading}>
            {loading ? "Loading" : "Connect Bank"}
          </Button>
          {loading && <Spinner />}
        </Flex>

        <Flex direction="column" mt="6" gap="2" style={{ opacity: 0.7 }}>
          <Heading size="3" color="gray" mt="2">
            How is my data handled?
          </Heading>
          <Text size="2" color="gray">
            Your privacy and security are our priorities. Finlink uses Plaid to
            securely connect your bank accounts. Plaid handles your login
            credentials, ensuring we never access or store them. Your data is
            always encrypted and protected.
          </Text>
          <Heading size="3" color="gray" mt="2">
            Is my financial data safe?
          </Heading>
          <Text size="2" color="gray">
            Absolutely. Plaid ensures all connections are secure and encrypted,
            and we only access the information necessary to sync your
            transactions to Google Sheets. Rest easy knowing your financial data
            is in safe hands.
          </Text>
        </Flex>
      </Flex>
    </FadeUp>
  );
};
