import { Flex, Heading, Spinner, Text } from "@radix-ui/themes";
import { FadeUp } from "../../../components/FadeUp";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSupabase } from "../../../contexts/SupabaseContexts";
import { API_URL } from "../../../util/config";
import { CheckIcon } from "@radix-ui/react-icons";

export const VerifyPayment = () => {
  const { supabase } = useSupabase();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isPaidUser, setIsPaidUser] = useState(false);

  // validate session using session id
  const validateSession = async (sessionId: string) => {
    if (!supabase) return;
    const {
      data: { session },
    } = await supabase.auth.getSession();
    if (!session) return;
    const accessToken = session.access_token;

    const response = await fetch(
      `${API_URL}/stripe/validate-session?session_id=${sessionId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`, // Add the bearer token here
          "Content-Type": "application/json",
        },
      }
    );
    const result = await response.json();
    console.log(result);
    if (result.success) {
      console.log("Payment validated and backend changes triggered");
      setIsPaidUser(true);
    } else {
      console.error("Session validation failed");
    }
  };

  useEffect(() => {
    // validate session based on url params
    const success = searchParams.get("success");
    const sessionId = searchParams.get("session_id");
    if (success && sessionId) {
      validateSession(sessionId);
    } else {
      navigate("/setup/subscription");
    }
  }, [searchParams]);

  useEffect(() => {
    if (isPaidUser) {
      setTimeout(() => {
        navigate("/setup/connect-bank");
      }, 1000);
    }
  }, [isPaidUser]);

  return (
    <FadeUp>
      <Flex direction="column" gap="4">
        {!isPaidUser ? (
          <Flex gap="2" align="center">
            <Spinner />
            <Heading>Completing Payment</Heading>
          </Flex>
        ) : (
          <Flex gap="2" align="center">
            <CheckIcon color="green" width="24px" height="24px" />
            <Heading>Success</Heading>
          </Flex>
        )}

        {!isPaidUser ? (
          <Text>Setting up your account.</Text>
        ) : (
          <Text>Let's set up your bank account connection.</Text>
        )}
      </Flex>
    </FadeUp>
  );
};
