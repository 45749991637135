import { Box, Button, Card, Flex, Grid, Heading, Text } from "@radix-ui/themes";
import { FadeUp } from "../../../components/FadeUp";
import { ScrollingLogos } from "./ScrollingLogos";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { useOutletContext } from "react-router-dom";

export const LandingContent = () => {
  const { handleLogin }: any = useOutletContext();

  return (
    <Flex direction="column" gap="9">
      {/* top landing content */}
      <Flex direction="column" gap="6">
        <Flex
          direction="column"
          justify="center"
          align="center"
          gap="5"
          mt="4"
          mx="9"
          px="9"
        >
          <Heading size="8" align="center">
            Effortlessly Connect Your Bank Accounts to Google Sheets
          </Heading>

          <FadeUp delay={0.1}>
            <Text align="center" color="gray">
              No more manual entry or complicated apps—just a clean, familiar
              Google Sheet where you can track, analyze, and manage your
              finances with ease.
            </Text>
          </FadeUp>
        </Flex>

        <Flex align="center" justify="center" mt="4">
          <FadeUp delay={0.2}>
            <Box width="200px" height="200px">
              <ScrollingLogos />
            </Box>
          </FadeUp>

          <FadeUp delay={0.2}>
            <Flex mr="6">
              <ArrowRightIcon width={24} height={24} color="#0026a3" />
            </Flex>
          </FadeUp>

          <FadeUp delay={0.3}>
            <Flex
              maxHeight="300px"
              maxWidth="550px"
              style={{ borderRadius: "25px", overflow: "hidden" }}
              className="shadow-box"
            >
              <img src="/imgs/budget.png" height="300" />
            </Flex>
          </FadeUp>
        </Flex>
      </Flex>

      {/* set up call to action */}
      <FadeUp delay={0.5}>
        <Card variant="ghost" mt="5">
          <Flex direction="column" gap="4" align="center">
            <Heading size="6">Set up in less than five minutes.</Heading>
            <Text color="gray">
              We'll drop a Google Sheets file right into your Google Drive.
            </Text>
            <Box>
              <Button size="4" radius="full" onClick={() => handleLogin()}>
                Sign up with Google
              </Button>
            </Box>
          </Flex>
        </Card>
      </FadeUp>

      {/* feature cards */}
      <Grid columns="3" gap="3" my="5" gapX="8">
        <Card variant="ghost">
          <Flex direction="column" gap="5">
            <Box height="150px" width="150px" pt="4">
              <img
                src="/imgs/landing/plaid_logo.svg"
                draggable="false"
                width="120px"
                height="120px"
                style={{ opacity: 0.75 }}
              />
            </Box>
            <Flex direction="column" gap="2">
              <Heading size="5">Safe & Secure Data</Heading>
              <Text size="3" color="gray">
                We will <strong>never</strong> access credentials. All bank
                connections handled securely via Plaid.
              </Text>
            </Flex>
          </Flex>
        </Card>

        <Card variant="ghost">
          <Flex direction="column" gap="5">
            <Box height="150px" width="150px">
              <img
                src="/imgs/landing/dashboard-3d.gif"
                draggable="false"
                width="140px"
                height="140px"
              />
            </Box>
            <Flex direction="column" gap="2">
              <Heading size="5">Custom Budgets</Heading>
              <Text size="3" color="gray">
                Create customized budgets that reflect your personal financial
                goals.
              </Text>
            </Flex>
          </Flex>
        </Card>

        <Card variant="ghost">
          <Flex direction="column" gap="5">
            <Box height="150px" width="150px">
              <img
                src="/imgs/landing/rocket-3d.gif"
                draggable="false"
                width="150px"
                height="150px"
              />
            </Box>
            <Flex direction="column" gap="2">
              <Heading size="5">Automatic Transactions</Heading>
              <Text size="3" color="gray">
                Your bank transactions are automatically updated to your Google
                Sheet.
              </Text>
            </Flex>
          </Flex>
        </Card>
      </Grid>
    </Flex>
  );
};
