import "@radix-ui/themes/styles.css";
import "./App.css";
import "./theme/colors.css";
import "./theme/font.css";

import { Theme, Container, Flex } from "@radix-ui/themes";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { Toaster } from "react-hot-toast";

// supabase
import { SupabaseProvider } from "./contexts/SupabaseContexts";

// pages
import { HomePage } from "./pages/home";
import { LandingPage } from "./pages/landing";
import SessionExpiredPage from "./pages/auth/SessionExpired";
import Footer from "./components/Footer";
import SetupPage from "./pages/setup";
import { CreateSubscription } from "./pages/setup/stages/CreateSubscription";
import { ConnectBank } from "./pages/setup/stages/ConnectBank";
import { VerifyPayment } from "./pages/setup/stages/VerifyPayment";
import { VerifyBankConnections } from "./pages/setup/stages/VerifyBankConnections";
import CreateSheet from "./pages/setup/stages/CreateSheet";
import { LandingContent } from "./pages/landing/landing";
import PricingPage from "./pages/landing/pricing";
import PrivacyPolicyPage from "./pages/landing/privacy-policy";
import TermsOfServicePage from "./pages/landing/terms-of-service";

function App() {
  return (
    <SupabaseProvider>
      <RecoilRoot>
        <Theme grayColor="slate" radius="large" panelBackground="translucent">
          <Toaster />
          <Container size="3" pt="8" px="2">
            <Flex justify="between" direction="column" minHeight="90vh">
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<LandingPage />}>
                    <Route index element={<LandingContent />} />
                    <Route path="pricing" element={<PricingPage />} />
                    <Route
                      path="privacy-policy"
                      element={<PrivacyPolicyPage />}
                    />
                    <Route
                      path="terms-of-service"
                      element={<TermsOfServicePage />}
                    />
                  </Route>
                  <Route path="/home" element={<HomePage />} />
                  <Route path="/setup" element={<SetupPage />}>
                    <Route
                      path="/setup/subscription"
                      element={<CreateSubscription />}
                    />
                    <Route
                      path="/setup/subscription-callback"
                      element={<VerifyPayment />}
                    />
                    <Route
                      path="/setup/connect-bank"
                      element={<ConnectBank />}
                    />
                    <Route
                      path="/setup/verify-bank-connections"
                      element={<VerifyBankConnections />}
                    />
                    <Route
                      path="/setup/create-sheet"
                      element={<CreateSheet />}
                    />
                  </Route>
                  <Route
                    path="/session-expired"
                    element={<SessionExpiredPage />}
                  />
                </Routes>
              </BrowserRouter>

              <Footer />
            </Flex>
          </Container>
        </Theme>
      </RecoilRoot>
    </SupabaseProvider>
  );
}

export default App;
