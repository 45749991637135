import { Flex, Heading, Link, Text } from "@radix-ui/themes";
import { FadeUp } from "../../../components/FadeUp";

export default function TermsOfServicePage() {
  return (
    <FadeUp>
      <Flex direction="column" gap="5">
        <Flex direction="column" gap="2" mt="4" mb="2">
          <Heading size="8">Terms of Service</Heading>
          <Text>Last Updated: November 6th, 2024</Text>
        </Flex>

        <Text>
          Welcome to Finlink (the “Service”). By accessing or using our web
          application, you agree to be bound by these Terms of Service (“Terms”)
          and comply with all applicable laws and regulations. Please read these
          Terms carefully.
        </Text>

        <Heading size="6">1. Acceptance of Terms</Heading>
        <Text>
          By using our Service, you acknowledge that you have read, understood,
          and agree to these Terms. If you do not agree with any part of these
          Terms, you must not use our Service.
        </Text>

        <Heading size="6">2. Service Description</Heading>
        <Text>
          Our Service allows users to connect their bank accounts securely to
          Google Sheets for budget management. We use third-party services,
          including Plaid, Stripe, and Google OAuth, to provide seamless and
          secure integrations for account linking, payment processing, and
          authentication.
        </Text>

        <Heading size="6">3. Use of Third-Party Services</Heading>

        <Text>
          Our Service relies on third-party services to provide core
          functionality. By using our Service, you also agree to the following
          terms and policies from these providers:
        </Text>

        <Text>
          <strong>Plaid Integration:</strong> We use Plaid to securely connect
          your bank accounts to our Service. By linking your accounts, you agree
          to Plaid’s{" "}
          <Link
            href="https://plaid.com/legal/#end-user-privacy-policy"
            target="_blank"
          >
            End User Privacy Policy
          </Link>
          . We do not store your bank login credentials; they are securely
          handled by Plaid. Plaid may collect certain information directly from
          you as part of the account connection process, which is subject to
          Plaid’s privacy terms.
        </Text>

        <Text>
          <strong>Stripe Payments:</strong> For subscription and payment
          processing, we use Stripe. All payment information is handled directly
          by Stripe, and we do not store your credit card or other payment
          details. By subscribing to our Service, you agree to Stripe’s{" "}
          <Link href="https://stripe.com/privacy" target="_blank">
            Privacy Policy
          </Link>{" "}
          and{" "}
          <Link href="https://stripe.com/legal" target="_blank">
            Terms of Service
          </Link>
          .
        </Text>

        <Text>
          <strong>Google OAuth Authentication:</strong> We use Google OAuth to
          authenticate users and to grant access to Google Sheets. By using
          Google OAuth, you agree to Google’s{" "}
          <Link href="https://policies.google.com/privacy" target="_blank">
            Privacy Policy
          </Link>{" "}
          and{" "}
          <Link href="https://policies.google.com/terms" target="_blank">
            Terms of Service
          </Link>
          . Our Service complies with Google’s Limited Use policy, and we only
          access data necessary for integrating with Google Sheets.
        </Text>

        <Heading size="6">4. User Obligations</Heading>
        <Text>
          You agree to use the Service responsibly and comply with all
          applicable laws. You are responsible for maintaining the
          confidentiality of your login credentials and agree not to share them
          with others. If you suspect unauthorized access to your account,
          please notify us immediately.
        </Text>

        <Heading size="6">5. Privacy and Data Security</Heading>
        <Text>
          We are committed to protecting your privacy and use industry-standard
          security measures to safeguard your information. For more details, see
          our <Link href="/privacy-policy">Privacy Policy</Link>.
        </Text>

        <Heading size="6">6. Subscription and Billing</Heading>
        <Text>
          By subscribing to our Service, you agree to our subscription terms and
          authorize Stripe to process your payment on our behalf. Subscription
          fees are billed on a recurring basis, and you may cancel at any time
          through your account settings.
        </Text>

        <Heading size="6">7. Limitation of Liability</Heading>
        <Text>
          Our Service is provided “as is” without warranties of any kind, either
          express or implied. We are not responsible for any direct, indirect,
          or incidental damages arising from the use of the Service. Use of
          third-party services, such as Plaid, Stripe, and Google OAuth, is at
          your own risk, and you are subject to the terms of each provider.
        </Text>

        <Heading size="6">8. Changes to the Terms</Heading>
        <Text>
          We may update these Terms from time to time. The updated Terms will be
          posted on this page, and continued use of the Service implies your
          acceptance of the new Terms.
        </Text>

        <Heading size="6">9. Contact Information</Heading>
        <Text>
          If you have any questions or concerns about these Terms of Service,
          please contact us via the contact form.
        </Text>
      </Flex>
    </FadeUp>
  );
}
