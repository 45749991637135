import { Box, Flex } from "@radix-ui/themes";
import { useEffect, useState, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";

export const ScrollingLogos = () => {
  const logos = [
    "/bank-logos/logo (10).png",
    "/bank-logos/logo (7).png",
    "/bank-logos/logo (2).png",
    "/bank-logos/logo (11).png",
    "/bank-logos/logo (8).png",
    "/bank-logos/logo (1).png",
    "/bank-logos/logo (3).png",
    "/bank-logos/logo (4).png",
    "/bank-logos/logo (5).png",
    "/bank-logos/logo (6).png",
    "/bank-logos/logo (9).png",
    "/bank-logos/plaid.jpeg",
  ];

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIndex((prevIndex) =>
        prevIndex === logos.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000);

    return () => clearTimeout(timer); // Clean up the timeout on unmount
  }, [index, logos.length]);

  // Memoize the rendering of the current logo with fade-in and fade-out animation
  const renderLogo = useMemo(() => {
    return (
      <Flex
        width="200px"
        height="200px"
        justify="center"
        align="center"
        style={{ opacity: 0.9 }}
      >
        <AnimatePresence>
          <motion.img
            key={index} // Key is important for re-render and triggering animations
            src={logos[index]}
            width="135"
            height="135"
            style={{
              position: "absolute",
              objectFit: "contain",
              borderRadius: 35,
            }}
            initial={{ opacity: 0, y: 20 }} // Start with opacity 0 and y-offset
            animate={{ opacity: 1, y: 0 }} // Animate to opacity 1 and y 0
            exit={{ opacity: 0, y: -20 }} // Fade-out and move upward when exiting
            transition={{ duration: 0.4, ease: "easeInOut" }} // Smooth transition for both in and out
            draggable="false"
          />
        </AnimatePresence>
      </Flex>
    );
  }, [index, logos]);

  return <Box>{renderLogo}</Box>;
};
