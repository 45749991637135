import { CheckIcon } from "@radix-ui/react-icons";
import { Box, Button, Card, Flex, Grid, Heading, Text } from "@radix-ui/themes";
import { FadeUp } from "../../../components/FadeUp";

export default function PricingPage() {
  const FeatureItem = ({
    title,
    description,
  }: {
    title: string;
    description: string;
  }) => {
    return (
      <Flex gap="2" align="start">
        <Box>
          <CheckIcon color="green" width="24px" height="24px" />
        </Box>
        <Flex direction="column" gap="1">
          <Heading size="3">{title}</Heading>
          <Text size="2">{description}</Text>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex direction="column" gap="4">
      <Flex direction="column" gap="4" mt="4" mb="2">
        <Heading size="8">pricing.</Heading>
        <Heading size="6" color="gray">
          See what payment plan works the best for you.
        </Heading>
      </Flex>

      <Grid columns={{ initial: "1", md: "2" }} gap="4">
        {/* starter plan */}
        <FadeUp>
          <Card>
            <Flex direction="column" gap="4" p="3">
              {/* title and price */}
              <Flex justify="between" align="center">
                <Heading size="7">starter.</Heading>
                <Heading size="5">$5/mo.</Heading>
              </Flex>
              {/* description */}
              <Text size="3">
                Perfect for individuals and freelancers who want to streamline
                their finances.
              </Text>
              {/* features */}
              <Flex direction="column" gap="3">
                {/* feature 1 */}
                <FeatureItem
                  title="Up to 3 Bank Accounts"
                  description="Keep your personal and business finances in sync effortlessly."
                />
                {/* feature 2 */}
                <FeatureItem
                  title="Real-Time Data Sync"
                  description="Automatically update your Google Sheets with the latest transactions."
                />
                {/* feature 3 */}
                <FeatureItem
                  title="Automated Data Entry"
                  description="Say goodbye to manual spreadsheet updates and errors."
                />
                {/* feature 4 */}
                <FeatureItem
                  title="Secure and Reliable"
                  description="Bank-level security ensures your data is protected at all times."
                />
              </Flex>

              <Button>Get Started</Button>
            </Flex>
          </Card>
        </FadeUp>

        {/* pro plan */}
        <FadeUp delay={0.1}>
          <Card>
            <Flex direction="column" gap="4" p="3">
              {/* title and price */}
              <Flex justify="between" align="center">
                <Heading size="7">pro.</Heading>
                <Heading size="5">$10/mo.</Heading>
              </Flex>
              {/* description */}
              <Text size="3">
                Ideal for power users or freelancers managing many accounts.
              </Text>
              {/* features */}
              <Flex direction="column" gap="2">
                {/* feature 1 */}
                <FeatureItem
                  title="Up to 8 Bank Accounts"
                  description="Manage all your financial accounts in one place for a holistic view."
                />
                {/* feature 2 */}
                <FeatureItem
                  title="Real-Time Data Sync"
                  description="Automatically update your Google Sheets with the latest transactions."
                />
                {/* feature 3 */}
                <FeatureItem
                  title="Automated Data Entry"
                  description="Say goodbye to manual spreadsheet updates and errors."
                />
                {/* feature 4 */}
                <FeatureItem
                  title="Secure and Reliable"
                  description="Bank-level security ensures your data is protected at all times."
                />
              </Flex>
              <Button>Get Started</Button>
            </Flex>
          </Card>
        </FadeUp>
      </Grid>

      {/* starter plan */}
      <FadeUp delay={0.2}>
        <Card>
          <Flex direction="column" gap="4" p="3">
            {/* title and price */}
            <Flex justify="between" align="center">
              <Heading size="6">custom.</Heading>
            </Flex>
            {/* description */}
            <Text size="3">
              Need unlimited bank connections or existing sheet integrations?
            </Text>
            <Text size="3">
              We're happy to create a custom plan just for you. Get in touch
              with us to discuss how we can help simplify your financial
              tracking in a way that works best for you.
            </Text>
            <Box>
              <Button>Contact Us</Button>
            </Box>
          </Flex>
        </Card>
      </FadeUp>
    </Flex>
  );
}
