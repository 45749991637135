import { useNavigate } from "react-router-dom";
import {
  AlertDialog,
  Box,
  Button,
  Card,
  Flex,
  Heading,
  IconButton,
  Skeleton,
  Text,
  TextField,
} from "@radix-ui/themes";
import { FadeUp } from "../../components/FadeUp";
import { useMemo } from "react";
import { CopyIcon, Link1Icon, TrashIcon } from "@radix-ui/react-icons";
import toast from "react-hot-toast";
import { deleteUserSheet } from "../../util/UserService";
import { useSupabase } from "../../contexts/SupabaseContexts";

export const SheetInfoCard = ({
  sheetId,
  loading,
  setLoading,
}: {
  sheetId: string | undefined;
  loading: boolean;
  setLoading: Function;
}) => {
  const navigate = useNavigate();
  const { supabase } = useSupabase();

  const handleViewSpreadsheet = async () => {
    window.open(`https://docs.google.com/spreadsheets/d/${sheetId}`, "_blank");
  };

  // click copy link
  const onCopyLinkClick = () => {
    navigator.clipboard.writeText(
      `https://docs.google.com/spreadsheets/d/${sheetId}/edit`
    );
    toast("Link copied!");
  };

  const renderDeleteButton = useMemo(() => {
    return (
      <AlertDialog.Root>
        <AlertDialog.Trigger>
          <Button variant="ghost" color="tomato" size="2">
            <TrashIcon />
            Delete
          </Button>
        </AlertDialog.Trigger>
        <AlertDialog.Content maxWidth="450px">
          <AlertDialog.Title>Are you sure?</AlertDialog.Title>
          <AlertDialog.Description size="2">
            Your sheet will <strong>not</strong> be deleted from your Google
            Drive files, but it <strong>will</strong> stop being updated by
            finlink.
          </AlertDialog.Description>

          <Flex gap="3" mt="3" justify="end">
            <AlertDialog.Cancel>
              <Button variant="soft" color="gray">
                Cancel
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action>
              <Button
                variant="solid"
                color="red"
                onClick={async () => {
                  if (supabase) {
                    const deleted = await deleteUserSheet(supabase);
                    if (deleted) {
                      toast.success("Deleted sheet");
                      setLoading(true);
                      return;
                    }
                  }
                  toast.error("Something went wrong...");
                }}
              >
                Yes, I'm sure
              </Button>
            </AlertDialog.Action>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    );
  }, []);

  const renderSheetInfo = useMemo(() => {
    const spreadsheetUrl = `https://docs.google.com/spreadsheets/d/${sheetId}/edit`;

    if (loading)
      return (
        <Flex gap="3" direction="column">
          <Flex gap="3" direction="column" maxWidth={"25%"}>
            <Skeleton />
            <Skeleton />
          </Flex>
          <Flex gap="3" direction="column" maxWidth={"75%"}>
            <Skeleton />
            <Skeleton />
          </Flex>
        </Flex>
      );
    if (sheetId)
      return (
        <Flex direction="column" gap="6">
          <Card>
            <Flex direction="column" gap="3" p="2">
              <Heading size="6">My Sheet</Heading>
              <TextField.Root
                size="3"
                variant="soft"
                contentEditable={false}
                onChange={() => {}}
                value={spreadsheetUrl}
                radius="large"
              >
                <TextField.Slot>
                  <Link1Icon height="16" width="16" />
                </TextField.Slot>
                <TextField.Slot>
                  <IconButton
                    size="2"
                    variant="ghost"
                    onClick={onCopyLinkClick}
                  >
                    <CopyIcon width="16px" height="16px" />
                  </IconButton>
                </TextField.Slot>
              </TextField.Root>

              <Flex justify="between" align="end">
                <Box>
                  <Button size="2" onClick={handleViewSpreadsheet}>
                    View Spreadsheet
                  </Button>
                </Box>

                <Box mr="1">{renderDeleteButton}</Box>
              </Flex>
            </Flex>
          </Card>

          {false && (
            <Flex direction="column">
              <Heading size="5">Recent Activity</Heading>

              <Flex mt="4" direction="column" gap="1">
                {[1, 2, 3, 4, 5].map((item, idx) => {
                  return (
                    <Flex gap="2" key={idx}>
                      <Text>DD:MM:YYYY - TTTT</Text>
                      <Text>Log activity details</Text>
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
          )}
        </Flex>
      );
    else
      return (
        <Flex gap="4" direction="column">
          <Heading size="6">Create a Finlink sheet.</Heading>

          <Text>You don't have any active finlink sheets.</Text>

          <Box>
            <Button size="3" onClick={() => navigate("/setup/subscription")}>
              Create
            </Button>
          </Box>
        </Flex>
      );
  }, [sheetId, loading]);

  return (
    <Flex direction="column" width="100%" gap="4">
      <FadeUp>{renderSheetInfo}</FadeUp>
    </Flex>
  );
};
