import { InstagramLogoIcon, TwitterLogoIcon } from "@radix-ui/react-icons";
import {
  Box,
  Card,
  Flex,
  Grid,
  Heading,
  IconButton,
  Link,
  Separator,
  Text,
} from "@radix-ui/themes";

export default function Footer() {
  return (
    <Flex direction="column" justify="end" flexGrow="1" pt="3" pb="6">
      <Separator my="6" size="4" />
      <Grid columns="2" gapX="8">
        {/* left column */}
        <Flex direction="column" gap="3">
          <Flex gap="3" align="center">
            <Heading color="gray" size="6">
              finlink.
            </Heading>
          </Flex>
          {/* quick links */}
          <Flex>
            <Text color="gray">
              <Link href="/" underline="hover">
                Home
              </Link>
              {" | "}
              <Link href="/pricing" underline="hover">
                Pricing
              </Link>
              {" | "}
              <Link href="/blog" underline="hover">
                Blog
              </Link>
              {" | "}
              <Link href="/privacy-policy" underline="hover">
                Privacy Policy
              </Link>
              {" | "}
              <Link href="/terms-of-service" underline="hover">
                Terms of Service
              </Link>
            </Text>
          </Flex>

          <Text size="3" color="gray">
            © 2024 Finlink. All rights reserved.
          </Text>

          <Text color="gray" style={{ opacity: 0.6 }} size="1">
            <Link
              href="https://icons8.com/illustrations/author/O3yWbvlcRmbU"
              underline="none"
            >
              Illustrations by Eugene Weiss from{" "}
            </Link>
            <Link href="https://icons8.com/illustrations" underline="none">
              Ouch!
            </Link>
          </Text>
        </Flex>

        {/* right column */}
        <Flex direction="column" gap="3">
          <Card variant="ghost">
            <Flex gap="2" direction="column">
              <Heading size="5" color="gray">
                Need help?
              </Heading>
              <Text size="3" color="gray">
                Contact us through{" "}
                <Link href="https://google.com">this form</Link> and we'll
                respond within 24 hours.
              </Text>
            </Flex>
          </Card>
        </Flex>
      </Grid>
    </Flex>
  );
}
