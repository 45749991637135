import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  IconButton,
  RadioCards,
  Separator,
  Spinner,
  Text,
} from "@radix-ui/themes";
import { useState } from "react";
import { MinusIcon, PlusIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { FadeUp } from "../../../components/FadeUp";
import { useSupabase } from "../../../contexts/SupabaseContexts";
import { API_URL, APP_URL } from "../../../util/config";
import { updateAccountCount } from "../../../util/UserService";

export const CreateSubscription = () => {
  const navigate = useNavigate();
  const { supabase } = useSupabase();
  const [selectedPlan, setSelectedPlan] = useState<"regular" | "premium">(
    "regular"
  );

  // loading state
  const [loading, setLoading] = useState(false);

  // creates a stripe session
  const handleStripeSession = async () => {
    if (!supabase) return;

    // update account count
    setLoading(true);
    let numAccounts;
    if (selectedPlan == "regular") numAccounts = 5;
    else numAccounts = 12;

    const updatedAccountCount = await updateAccountCount(supabase, numAccounts);
    if (!updateAccountCount) return;

    // start stripe session
    const {
      data: { session },
    } = await supabase.auth.getSession();
    if (!session) return;
    const accessToken = session.access_token;

    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (!user) return;
    const email = user.email;

    // set lookup key based on plan selection
    let lookup_key = "regular-plan";
    if (selectedPlan == "premium") lookup_key = "premium-plan";

    const response = await fetch(API_URL + "/stripe/create-checkout-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        lookup_key: lookup_key,
        customer_email: email,
        app_url: APP_URL,
      }),
    });
    const data = await response.json();
    console.log(data);
    if (response.ok && data.url) {
      window.location.assign(data.url);
    } else console.log(response);

    setLoading(false); // stop loading
  };

  return (
    <FadeUp>
      <Flex direction="column" gap="5">
        <Heading>Choose Your Plan</Heading>

        {/* cards */}
        <Flex gap="4" width="100%">
          <RadioCards.Root
            defaultValue="regular"
            columns={{ initial: "1", sm: "2" }}
            value={selectedPlan}
            onValueChange={(e: "regular" | "premium") => setSelectedPlan(e)}
          >
            <RadioCards.Item value="regular">
              <Flex direction="column" gap="2">
                <Heading size="4">Regular Plan</Heading>
                <Separator size="4" />
                <Text>5 account connections</Text>
                <Text>$5 / mo.</Text>
              </Flex>
            </RadioCards.Item>
            <RadioCards.Item value="premium">
              <Flex direction="column" gap="2">
                <Heading size="4">Premium Plan</Heading>
                <Separator size="4" />
                <Text>12 account connections</Text>
                <Text>$10 / mo.</Text>
              </Flex>
            </RadioCards.Item>
          </RadioCards.Root>
        </Flex>

        {/* payment section */}
        <Flex gap="3" align="center">
          <Button size="3" onClick={handleStripeSession} disabled={loading}>
            {loading ? "Loading" : "Make Payment"}
            {loading && <Spinner />}
          </Button>
          <Text size="2" color="gray" mr="-2">
            Powered by
          </Text>
          <img src="/logos/stripe_logo.png" height={16} />
        </Flex>

        {/* extra information */}
        <Flex direction="column" mt="4" gap="2" style={{ opacity: 0.7 }}>
          <Heading size="3" color="gray" mt="2">
            How will I be billed?
          </Heading>
          <Text size="2" color="gray">
            We use Stripe to handle payments, so we will never access your
            payment details. You may cancel your subscription at any time. Refer
            to the Terms of Service for more information.
          </Text>
          <Heading size="3" color="gray" mt="2">
            Can I add more accounts later?
          </Heading>
          <Text size="2" color="gray">
            Yes, you can add accounts at any time by logging into Finlink in
            your account settings.
          </Text>
        </Flex>
      </Flex>
    </FadeUp>
  );
};
