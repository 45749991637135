import {
  Avatar,
  Box,
  Card,
  Flex,
  Heading,
  Progress,
  Skeleton,
  Text,
} from "@radix-ui/themes";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useSupabase } from "../../contexts/SupabaseContexts";
import { User } from "../../recoil/user";
import { getUser } from "../../util/UserService";

export default function SetupPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { supabase } = useSupabase();
  const [user, setUser] = useState<User | undefined>();
  const { pathname } = location;

  /* progress bar render */
  const renderProgressBarData = useMemo(() => {
    let value, label, step;

    if (pathname == "/setup/subscription") {
      value = 5;
      label = "Select Number of Accounts";
      step = "1";
    } else if (pathname == "/setup/subscription-callback") {
      value = 25;
      label = "Verifying Purchase";
      step = "1";
    } else if (pathname == "/setup/connect-bank") {
      value = 50;
      label = "Connect Bank with Plaid";
      step = "2";
    } else if (pathname == "/setup/verify-bank-connections") {
      value = 75;
      label = "Bank Accounts Summary";
      step = "3";
    } else if (pathname == "/setup/create-sheet") {
      value = 100;
      label = "Create Sheet";
      step = "4";
    } else {
      value = 0;
      label = "NA";
      step = "NA";
    }

    return {
      value: value,
      label: label,
      step: step,
    };
  }, [pathname]);

  useEffect(() => {
    const updateUser = async () => {
      if (!supabase) return;
      const googleUser = await getUser(supabase);
      if (!googleUser) navigate("/session-expired");
      else setUser(googleUser);
    };
    updateUser();
  }, [supabase]);

  useEffect(() => {
    console.log("user:", user);
  }, [user?.profileImg]);

  return (
    <Flex direction="column">
      {/* title */}
      <Flex justify="between" align="center" mb="6">
        <Flex gap="1" align="center">
          <Heading>finlink.</Heading>
        </Flex>

        <Flex align="center" gap="2">
          <Text size="3" weight="bold">
            <Skeleton loading={!user}>{user?.fullName}</Skeleton>
          </Text>
          <Avatar
            src={user?.profileImg || ""}
            fallback=""
            size="2"
            radius="full"
          />
        </Flex>
      </Flex>

      {/* progress bar */}
      <Box>
        <Card variant="classic">
          <Flex justify="between" mb="1">
            <Text size="3">Step {renderProgressBarData.step}</Text>
            <Text size="3">{renderProgressBarData.label}</Text>
          </Flex>
          <Progress value={renderProgressBarData.value} size="2" />
        </Card>
      </Box>

      {/* content */}
      <Box mt="6">
        <Outlet />
      </Box>
    </Flex>
  );
}
