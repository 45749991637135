import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  IconButton,
  Spinner,
  Text,
  TextField,
} from "@radix-ui/themes";
import { FadeUp } from "../../../components/FadeUp";
import { useEffect, useState } from "react";
import { useSupabase } from "../../../contexts/SupabaseContexts";
import { API_URL } from "../../../util/config";
import { CheckIcon, CopyIcon, Link1Icon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export default function CreateSheet() {
  const { supabase } = useSupabase();
  const navigate = useNavigate();

  // data resp
  const [sheetId, setSheetId] = useState("");

  const createGoogleSheet = async () => {
    if (!supabase) return;
    const {
      data: { session },
      error,
    } = await supabase.auth.getSession();
    if (!session) {
      console.error("No active session");
      return;
    }

    const supabaseToken = session.access_token; // Supabase access token for auth
    const googleToken = session.provider_token; // Google access token for Google API access

    if (!supabaseToken || !googleToken) {
      console.error("Missing tokens for authentication");
      return;
    }

    try {
      const response = await fetch(API_URL + "/google/create-sheet", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${supabaseToken}`, // Use Supabase token for backend authentication
        },
        body: JSON.stringify({
          googleToken: googleToken, // Use Google token for Google API access
        }),
      });

      const resp = await response.json();
      if (response.ok) {
        const data = resp.sheet;
        console.log("Google Sheet created:", data);
        setSheetId(data.spreadsheetId);
      } else {
        console.error("Failed to create Google Sheet:", resp.error);
      }
    } catch (error) {
      console.error("Error making request:", error);
    }
  };

  // click copy link
  const onCopyLinkClick = () => {
    navigator.clipboard.writeText(
      `https://docs.google.com/spreadsheets/d/${sheetId}/edit`
    );
    toast("Link copied!");
  };

  useEffect(() => {
    createGoogleSheet();
  }, []);

  if (sheetId == "") {
    return (
      <Flex direction="column" gap="4">
        <Flex gap="2" align="center">
          <Spinner />
          <Heading>Creating Sheet</Heading>
        </Flex>
        <Text>Sit tight! This won't take very long.</Text>
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap="6">
      <FadeUp>
        <Card>
          <Flex direction="column" gap="4" p="2">
            <Flex gap="2" align="center">
              <CheckIcon color="green" width="24px" height="24px" />
              <Heading>Complete</Heading>
            </Flex>

            <TextField.Root
              size="3"
              variant="soft"
              contentEditable={false}
              onChange={() => {}}
              value={`https://docs.google.com/spreadsheets/d/${sheetId}/edit`}
              radius="large"
            >
              <TextField.Slot>
                <Link1Icon height="16" width="16" />
              </TextField.Slot>
              <TextField.Slot>
                <IconButton size="2" variant="ghost" onClick={onCopyLinkClick}>
                  <CopyIcon width="16px" height="16px" />
                </IconButton>
              </TextField.Slot>
            </TextField.Root>

            <Box>
              <Button
                onClick={() =>
                  window.open(
                    `https://docs.google.com/spreadsheets/d/${sheetId}/edit`,
                    "_blank"
                  )
                }
                size="3"
              >
                View Spreadsheet
              </Button>
            </Box>
          </Flex>
        </Card>
      </FadeUp>

      <FadeUp delay={0.1}>
        <Flex direction="column" gap="4" mt="2">
          <Heading size="5" color="gray">
            Where Can I Find My Sheet?
          </Heading>
          <Text size="3" color="gray">
            <strong>In Your Google Drive</strong>. The sheet has been saved
            directly to your Google Drive. You can access it by going to Google
            Drive and searching for the name of your sheet.
          </Text>

          <Heading size="5" color="gray" mt="4">
            How to Use Your Sheet
          </Heading>
          <Text size="3" color="gray">
            <strong>Track Transactions</strong>. All your latest data will
            automatically sync to the sheet whenever updates are available. You
            don’t need to manually enter any transactions—everything will be
            updated based on the events received.
          </Text>
          <Text size="3" color="gray">
            <strong>Categorize and Analyze</strong>. Easily categorize your
            transactions to monitor spending, create visualizations, and keep
            track of where your money is going. You can use filters and charts
            in Google Sheets to analyze your spending habits.
          </Text>
        </Flex>
      </FadeUp>

      <FadeUp delay={0.2}>
        <Box>
          <Button size="2" onClick={() => navigate("/home")}>
            Back Home
          </Button>
        </Box>
      </FadeUp>
    </Flex>
  );
}
