import {
  Box,
  Button,
  CheckboxCards,
  Flex,
  Grid,
  Heading,
  Skeleton,
  Spinner,
  Text,
} from "@radix-ui/themes";
import { FadeUp } from "../../../components/FadeUp";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSupabase } from "../../../contexts/SupabaseContexts";
import { API_URL } from "../../../util/config";

export interface SupabaseBankAccountItem {
  account_id: string;
  account_type: string;
  account_name: string;
  is_active: boolean;
}

export const VerifyBankConnections = () => {
  const { supabase } = useSupabase();
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState<SupabaseBankAccountItem[]>([]);
  const [numAllowedAccounts, setNumAllowedAccounts] = useState<
    number | undefined
  >();
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);

  // error message
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // Fetch user accounts when the component mounts
    const fetchAccounts = async () => {
      if (!supabase) return;
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (!session) return;
      const accessToken = session.access_token;

      fetch(API_URL + "/plaid/get-user-accounts", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setAccounts(data.accounts);
          // Set initially selected accounts
          const activeAccounts = data.accounts
            .filter((account: SupabaseBankAccountItem) => account.is_active)
            .map((account: SupabaseBankAccountItem) => account.account_id);
          setSelectedAccounts(activeAccounts);
        })
        .catch((error) => console.error("Error fetching accounts:", error));
    };
    fetchAccounts();

    // get plan
    const fetchPlan = async () => {
      if (!supabase) return;
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase
        .from("users")
        .select("account_count")
        .eq("user_id", user.id) // Replace with the actual user ID variable
        .single(); // Use single() to get a single row
      if (!data) return;
      else setNumAllowedAccounts(data.account_count);
    };
    fetchPlan();
  }, []);

  // on next
  const handleSubmit = async () => {
    if (!numAllowedAccounts) return;
    if (!supabase) return;
    const {
      data: { session },
    } = await supabase.auth.getSession();
    if (!session) return;
    const accessToken = session.access_token;

    const selectedCount = selectedAccounts.length;

    if (selectedCount == 0) {
      // Handle the case where no accounts are selected
      setErrorMessage(`Please select at least one account.`);
      return;
    }

    if (selectedCount > numAllowedAccounts) {
      // Handle the case where too many accounts are selected
      setErrorMessage(
        `Your plan has a maximum of ${numAllowedAccounts} accounts. You have selected ${selectedCount}.`
      );
      return;
    }

    // Clear any existing error messages
    setErrorMessage("");

    // Proceed to update account activation
    const response = await fetch(API_URL + "/plaid/update-account-activation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ selectedAccounts }),
    });

    const result = await response.json();

    if (response.ok && result.success) {
      // handle success
      navigate("/setup/create-sheet");
    } else {
      console.error(
        "Error updating account activation:",
        result.error || "Unknown error"
      );
      setErrorMessage("An unknown error occurred.");
    }
  };

  {
    /* selected accounts number indicator */
  }
  const renderSelectedIndicator = useMemo(() => {
    if (numAllowedAccounts && selectedAccounts.length > numAllowedAccounts) {
      return (
        <Text color="tomato">
          {selectedAccounts.length}/{numAllowedAccounts} selected
        </Text>
      );
    }

    return (
      <Text color="gray">
        {selectedAccounts.length}/
        <Skeleton loading={!numAllowedAccounts}>{numAllowedAccounts}</Skeleton>{" "}
        selected
      </Text>
    );
  }, [selectedAccounts, numAllowedAccounts]);

  return (
    <FadeUp>
      <Flex direction="column" gap="6">
        {/* heading */}
        <Flex direction="column" gap="2">
          <Heading>Choose Accounts</Heading>
          <Text>
            Choose the accounts to activate and sync with your Google Sheets.
          </Text>
        </Flex>

        <Flex justify="between" align="end">
          <Flex direction="column" gap="2">
            {/* your plan */}
            <Heading size="5">
              Your plan includes{" "}
              <Skeleton loading={!numAllowedAccounts}>
                {numAllowedAccounts}
              </Skeleton>{" "}
              accounts.
            </Heading>
            {/* upgrade account */}
            <Flex align="center" gap="4">
              <Text>Need more?</Text>
              <Button variant="ghost" size="2">
                Upgrade Account
              </Button>
            </Flex>
          </Flex>

          {/* indicator */}
          <Flex>{renderSelectedIndicator}</Flex>
        </Flex>

        {/* choose banks */}
        <Flex direction="column" gap="2">
          <CheckboxCards.Root
            value={selectedAccounts}
            onValueChange={setSelectedAccounts}
            columns={{ initial: "1" }}
            variant="classic"
          >
            {accounts.map((item: SupabaseBankAccountItem) => {
              return (
                <CheckboxCards.Item
                  value={item.account_id}
                  key={item.account_id}
                  style={
                    selectedAccounts.includes(item.account_id)
                      ? { backgroundColor: "var(--blue-8)" }
                      : {}
                  }
                >
                  <Grid columns="2" gap="3" flexGrow="1">
                    {/* account name */}
                    <Flex direction="column" width="100%">
                      <Text size="2" color="gray">
                        Account
                      </Text>
                      <Heading size="5">{item.account_name}</Heading>
                    </Flex>
                    <Flex direction="column" width="100%">
                      <Text size="2" color="gray">
                        Type
                      </Text>
                      <Heading size="4">{item.account_type}</Heading>
                    </Flex>
                  </Grid>
                </CheckboxCards.Item>
              );
            })}
          </CheckboxCards.Root>
        </Flex>

        {/* actions */}
        <Flex justify="between" onClick={handleSubmit} align="center">
          <Text color="tomato" size="4" weight="bold">
            {errorMessage}
          </Text>
          <Button size="4">Complete</Button>
        </Flex>
      </Flex>
    </FadeUp>
  );
};
