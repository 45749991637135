import {
  Avatar,
  Box,
  Flex,
  Heading,
  Separator,
  Skeleton,
  Text,
} from "@radix-ui/themes";
import { useSupabase } from "../../contexts/SupabaseContexts";
import { useEffect, useState } from "react";
import { User } from "../../recoil/user";
import {
  getSheetInfo,
  getUser,
  getUserOnboardingStatus,
  updateDatabaseWithGoogleInfo,
} from "../../util/UserService";
import { useNavigate } from "react-router-dom";
import { FadeUp } from "../../components/FadeUp";
import { SheetInfoCard } from "./SheetInfoCard";
import { SettingsSection } from "./SettingsSection";

export function HomePage() {
  const navigate = useNavigate();
  const { supabase } = useSupabase();
  const [user, setUser] = useState<User | undefined>();

  // user sheet info
  const [sheetId, setSheetId] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const updateUser = async () => {
      if (!supabase) return;

      // get onboarding status
      const onboardingStatus = await getUserOnboardingStatus(supabase);
      if (!onboardingStatus) navigate("/setup/subscription");

      // get sheet id
      const sheetId = await getSheetInfo(supabase);
      setSheetId(sheetId);
      setLoading(false);

      // get user google info
      const googleUser = await getUser(supabase);
      if (googleUser) {
        await updateDatabaseWithGoogleInfo(supabase, setUser);
        setUser(googleUser);
      } else {
        navigate("/session-expired");
        return;
      }
    };
    updateUser();
  }, [supabase, setUser, loading]);

  return (
    <Flex direction="column" gap="5" mt="4">
      <Flex justify="between" align="center">
        <Heading size="7">finlink.</Heading>

        {/* account box */}
        <Flex gap="3" align="center">
          {/* user info */}
          <Flex direction="column" align="end">
            <Heading size="3">
              <Skeleton loading={!user}>
                {user ? user.fullName : "John Doe"}
              </Skeleton>
            </Heading>
            <Text size="2" color="gray">
              <Skeleton loading={!user}>
                {user ? user.email : "example@email.com"}
              </Skeleton>
            </Text>
          </Flex>
          {/* profile pic */}
          <Avatar
            src={user?.profileImg || ""}
            fallback=""
            size="3"
            radius="full"
          />
        </Flex>
      </Flex>

      <FadeUp>
        <SheetInfoCard
          sheetId={sheetId}
          loading={loading}
          setLoading={setLoading}
        />
      </FadeUp>

      <FadeUp delay={0.1}>
        <Separator size="4" />
      </FadeUp>

      <FadeUp delay={0.2}>
        <SettingsSection />
      </FadeUp>
    </Flex>
  );
}
