import { Flex, Heading, Text } from "@radix-ui/themes";
import { FadeUp } from "../../../components/FadeUp";

export default function PrivacyPolicyPage() {
  return (
    <FadeUp>
      <Flex direction="column" gap="5">
        <Flex direction="column" gap="2" mt="4" mb="2">
          <Heading size="8">Privacy Policy</Heading>
          <Text>Last Updated: November 6th, 2024</Text>
        </Flex>

        <Text>
          Finlink (“we,” “us,” or “our”) is committed to protecting your
          privacy. This Privacy Policy explains how we collect, use, disclose,
          and safeguard your information when you use our web application (the
          “Service”) to securely connect bank accounts via Plaid to manage your
          budget in Google Sheets. By using our Service, you agree to the
          practices described in this Privacy Policy.
        </Text>

        <Heading size="6">1. Information We Collect</Heading>
        <Text>
          <strong>Personal Information</strong>
          <br />
          To use our Service, we may collect:
          <ul>
            <li>
              <strong>Contact Information</strong>: Such as your name and email
              address, provided by you.
            </li>
            <li>
              <strong>Banking Information</strong>: Information related to your
              financial transactions, securely processed through{" "}
              <strong>Plaid</strong>.
            </li>
            <li>
              <strong>Payment Information</strong>: For subscriptions, processed
              securely through <strong>Stripe</strong>.
            </li>
          </ul>
        </Text>
        <Text>
          <strong>Authentication Information</strong>
          <br />
          We use <strong>Google OAuth</strong> to authenticate your Google
          account, allowing you to link your Google Sheets to our Service. By
          using Google OAuth, we access and process only the data necessary to
          manage your budgeting spreadsheets. We do not have access to your
          Google account password.
        </Text>

        <Heading size="6">2. How We Use Your Information</Heading>
        <Text>
          Your information is used solely to provide and improve our Service,
          specifically:
          <ul>
            <li>
              <strong>Account Authentication</strong>: For secure access to your
              Google Sheets through Google OAuth.
            </li>
            <li>
              <strong>Transaction Data Sync</strong>: To synchronize your
              transaction data with your Google Sheets, allowing seamless budget
              management.
            </li>
            <li>
              <strong>Payment Processing</strong>: For subscriptions and
              payments through Stripe.
            </li>
          </ul>
          We do not sell, rent, or lease your information to third parties.
        </Text>

        <Heading size="6">3. How We Share Your Information</Heading>
        <Text>
          We may share your information as follows:
          <ul>
            <li>
              <strong>With Service Providers</strong>: Such as Plaid for secure
              banking connections, Stripe for payment processing, and Google
              OAuth for authentication.
            </li>
            <li>
              <strong>For Legal Compliance</strong>: To comply with applicable
              laws, regulations, or legal processes.
            </li>
          </ul>
        </Text>

        <Heading size="6">4. Third-Party Services and Data Handling</Heading>
        <Text>
          We integrate with the following third-party services:
          <ul>
            <li>
              <strong>Plaid</strong>: We use Plaid to link your bank accounts
              securely. By using our Service, you agree to Plaid’s{" "}
              <a href="https://plaid.com/legal/#end-user-privacy-policy">
                End User Privacy Policy
              </a>
              .
            </li>
            <li>
              <strong>Stripe</strong>: For payment processing, we use Stripe. By
              subscribing to our Service, you agree to Stripe’s{" "}
              <a href="https://stripe.com/privacy">Privacy Policy</a>.
            </li>
            <li>
              <strong>Google OAuth</strong>: We use Google OAuth for
              authentication and to access your Google Sheets. We comply with
              Google’s Limited Use policy, and we only access and use
              information necessary to provide our Service.
            </li>
          </ul>
          We ensure compliance with each of these providers’ standards and take
          appropriate measures to secure your data.
        </Text>

        <Heading size="6">5. Data Security</Heading>
        <Text>
          We implement industry-standard security practices to protect your
          information:
          <ul>
            <li>
              <strong>Encryption</strong>: Data is encrypted both in transit and
              at rest.
            </li>
            <li>
              <strong>Google Limited Use Compliance</strong>: We strictly adhere
              to Google’s Limited Use requirements, accessing only the data
              necessary to provide our service, and we do not transfer, sell, or
              share your Google data for any purpose beyond what is necessary
              for our application.
            </li>
          </ul>
        </Text>

        <Heading size="6">6. Your Rights</Heading>
        <Text>
          You have the right to:
          <ul>
            <li>
              <strong>Access Your Data</strong>: You may request access to the
              information we collect and process.
            </li>
            <li>
              <strong>Request Deletion</strong>: You may request the deletion of
              your personal data from our records.
            </li>
            <li>
              <strong>Withdraw Consent</strong>: If you no longer want us to
              access your Google Sheets, you may revoke access via your Google
              Account settings.
            </li>
          </ul>
        </Text>

        <Heading size="6">7. Changes to This Privacy Policy</Heading>
        <Text>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </Text>

        <Heading size="6">8. Contact Us</Heading>
        <Text>
          If you have any questions or concerns about this Privacy Policy,
          please contact us using the form provided.
        </Text>
      </Flex>
    </FadeUp>
  );
}
