import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  HoverCard,
  Link,
  Select,
  Text,
} from "@radix-ui/themes";

export const Navbar = ({ handleLogin }: { handleLogin: Function }) => {
  return (
    <Grid columns="3" width="100%" align="baseline">
      {/* logo */}
      <Heading size="5" color="gray">
        finlink.
      </Heading>

      {/* links */}
      <Flex gap="5" align="center" justify="center">
        <Link color="gray" href="/">
          <Text size="4">About</Text>
        </Link>
        <Link color="gray" href="/pricing">
          <Text size="4">Pricing</Text>
        </Link>
        {/* more link hover card */}
        <HoverCard.Root>
          <HoverCard.Trigger>
            <Link color="gray" style={{ cursor: "pointer" }}>
              <Text size="4">More</Text>
            </Link>
          </HoverCard.Trigger>
          <HoverCard.Content size="2" maxWidth="360px">
            {/* blog link */}
            <Link color="gray" href="/blog">
              <Text as="div" size="3" trim="start">
                Blog
              </Text>
            </Link>
            {/* privacy policy */}
            <Link color="gray" href="/privacy-policy">
              <Text as="div" size="3">
                Privacy Policy
              </Text>
            </Link>
            {/* terms of service */}
            <Link color="gray" href="/terms-of-service">
              <Text as="div" size="3" trim="end">
                Terms of Service
              </Text>
            </Link>
          </HoverCard.Content>
        </HoverCard.Root>
      </Flex>

      {/* login */}
      <Flex justify="end">
        <Flex direction="column" gap="2" align="end">
          <Button
            size="3"
            radius="full"
            color="gray"
            variant="soft"
            highContrast
            onClick={() => handleLogin()}
          >
            <img src="/imgs/google-icon.webp" height="16px" width="16px" />
            Sign Up with Google
          </Button>
          <Flex gap="1" align="center" mr="3">
            <Text size="3" color="gray">
              Have an account?
            </Text>{" "}
            <Button onClick={() => handleLogin()} variant="ghost" size="2">
              <Text size="3">Login</Text>
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Grid>
  );
};
