import { Flex, Heading, Text } from "@radix-ui/themes";

export const SettingsSection = () => {
  return (
    <Flex direction="column" gap="3">
      <Heading size="5">Account Settings</Heading>

      {/* google account */}
      <Flex direction="column" gap="2">
        <Text>TODO</Text>
        <Text>Google Account Deactivate/Connection</Text>
        <Text>Stripe/Subscription Manage</Text>
        <Text>Bank Accounts Manage</Text>
        <Text>Delete my data</Text>
      </Flex>
    </Flex>
  );
};
