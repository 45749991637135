import React, { ReactNode } from "react";
import { motion } from "framer-motion";

interface FadeUpProps {
  children: ReactNode;
  delay?: number;
}

export const FadeUp: React.FC<FadeUpProps> = ({ children, delay = 0 }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{
        duration: 0.45,
        ease: [0.6, -0.05, 0.01, 0.99],
        delay: delay,
      }}
    >
      {children}
    </motion.div>
  );
};
