import { Box, Button, Card, Flex, Grid, Heading, Text } from "@radix-ui/themes";
import { useSupabase } from "../../contexts/SupabaseContexts";
import { useEffect, useState } from "react";
import { Session } from "@supabase/supabase-js";
import { Navbar } from "./Navbar";
import { ScrollingLogos } from "./landing/ScrollingLogos";
import { FadeUp } from "../../components/FadeUp";
import { APP_URL } from "../../util/config";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { Outlet } from "react-router-dom";

export function LandingPage() {
  const { supabase } = useSupabase();
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    if (!supabase) return;
    // Fetch the current session on component mount
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    // Set up a listener for auth state changes (e.g., login, logout)
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    // Cleanup the subscription on component unmount
    return () => subscription.unsubscribe();
  }, [supabase]);

  const handleLogin = async () => {
    if (!supabase) return;

    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        scopes: "https://www.googleapis.com/auth/spreadsheets", // Request Google Sheets scope
        redirectTo: APP_URL + "/home",
        queryParams: {
          access_type: "offline",
          prompt: "consent",
        },
      },
    });
  };

  return (
    <Flex direction="column" gap="9" mt="4">
      <Navbar handleLogin={handleLogin} />

      <Outlet context={{ handleLogin }} />
    </Flex>
  );
}
