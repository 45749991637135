import React, { createContext, useContext, ReactNode } from "react";
import { createClient, SupabaseClient } from "@supabase/supabase-js";

// Define the types for your context
interface SupabaseContextType {
  supabase: SupabaseClient | null;
}

// Create a context with a default value of null
const SupabaseContext = createContext<SupabaseContextType>({
  supabase: null,
});

// Custom hook to use the Supabase context
export const useSupabase = () => useContext(SupabaseContext);

// Supabase client creation
const supabaseUrl = "https://ojhymhgsvkwoidaceqha.supabase.co";
const supabaseAnonKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9qaHltaGdzdmt3b2lkYWNlcWhhIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjUyMTE3MDAsImV4cCI6MjA0MDc4NzcwMH0.4MmSrZRFpuxDRnBE9qwW0dPNaA7L-Byv2CH2iA0O4t4";
const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Provider component
interface SupabaseProviderProps {
  children: ReactNode;
}

export const SupabaseProvider: React.FC<SupabaseProviderProps> = ({
  children,
}) => {
  return (
    <SupabaseContext.Provider value={{ supabase }}>
      {children}
    </SupabaseContext.Provider>
  );
};
